import React from "react";
import styled from "styled-components";
import { Badge } from "antd";
import { EventDataType } from "../types/EventTypes";

const UpcomingEventDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  .ant-badge {
    padding-left: 5%;
    .ant-badge-status-dot {
      height: 10px;
      width: 10px;
    }
  }
  div {
    padding-left: 12.5%;
  }
`;
const UpcomingEvent = ({
  event,
  color,
}: {
  event: EventDataType;
  color: string;
}) => {
  return (
    <UpcomingEventDiv>
      <Badge
        color={color}
        text={`${event.startTimestamp.format(
          "Do MMM"
        )} - ${event.endTimestamp.format("Do MMM")}`}
        style={{ fontSize: "1rem" }}
      />
      <div>
        {event.startTimestamp.format("hh:mm a")} -{" "}
        {event.endTimestamp.format("hh:mm a")}
      </div>
      <div style={{ fontSize: "1.125rem", fontWeight: 600 }}>{event.name}</div>
    </UpcomingEventDiv>
  );
};

export default UpcomingEvent;
