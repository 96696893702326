import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Layout, Menu, Typography, Button } from "antd";
import {
  ClockCircleOutlined,
  CalendarOutlined,
  ProjectOutlined,
  SettingOutlined,
  LeftOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { setAuthentication, setHeaderTitle } from "../reducers/authSlice";
import { setHeaderBackButtonVisible } from "../reducers/featuresSlice";
import { DashboardIcon, LogoutIcon, PersonIcon, UserIcon } from "../icons";
import { RootState } from "../reducers";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const MainDiv = styled(Layout)`
  min-width: 100%;
`;
const Options = styled(Sider)`
  height: 100vh;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 2;
  background: #2f2e41;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const AppTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  height: 10vh;
  font-weight: 700;
  font-size: 0.875rem;
  text-align: center;
`;
const OptionsMenu = styled(Menu)`
  background: #2f2e41;
  .ant-menu-item {
    height: 60px;
    .ant-menu-title-content {
      color: #ffffff;
      font-size: 1rem;
    }
    ::after {
      display: none;
    }
  }
  .ant-menu-item-selected,
  .ant-menu-item-active {
    background: #3e3c56 !important;
  }
  .ant-menu-item-icon {
    color: #ffffff;
    svg {
      font-size: 24px;
    }
  }
`;
const NoStyleLink = styled(Link)`
  text-decoration: none !important;
  color: #ffffff !important;
`;
const LogoutOption = styled(Button)`
  width: 100%;
  background-color: #2f2e41;
  color: #eeeeee;
  border-style: none;
  display: flex;
  align-items: center;
  padding-left: 60px;
  .anticon {
    font-size: 1rem;
  }
  :hover,
  :active,
  :focus {
    background: #2f2e41;
    color: #eeeeee;
  }
`;

const MainContent = styled(Content)`
  padding-top: 2.5%;
  background: #ffffff;
`;
const ContentHeader = styled(Header)`
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  h1 {
    font-weight: 700;
    font-size: 2.25rem;
  }
  padding: 45px 4.2% 25px 4.2%;
`;
const BackButton = styled(Button)`
  border-style: none;
  margin: 0 1rem 1rem 0;
`;
const ContentContainer = styled(Layout)``;

const AuthenticatedPageContainer = (props: any) => {
  const history = useHistory();
  const headerTitle = useSelector((state: RootState) => state.auth.headerTitle);
  const backButtonVisible = useSelector(
    (state: RootState) => state.features.headerBackButtonVisible
  );
  const dispatch = useDispatch();
  const handleLogout = () => {
    console.log(props);
    dispatch(setAuthentication(false));
  };
  const menuItems = [
    "Dashboard",
    "Timelog",
    "Calendar",
    "Projects",
    "Clients",
    "Tags",
    "Settings",
    "User",
  ];
  const menuItemIcons = [
    <DashboardIcon />,
    <ClockCircleOutlined />,
    <CalendarOutlined />,
    <ProjectOutlined />,
    <PersonIcon />,
    <TagOutlined />,
    <SettingOutlined />,
    <UserIcon />,
  ];

  return (
    <MainDiv>
      <Options
        width="240px"
        breakpoint="xl"
        collapsedWidth="0"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {}}
      >
        <OptionsMenu
          mode="inline"
          inlineIndent={60}
          defaultSelectedKeys={["0"]}
          selectedKeys={[headerTitle]}
        >
          <AppTitle>TRACK HOURS</AppTitle>
          {menuItems.map((menuItem, index) => {
            return (
              <Menu.Item
                key={menuItem}
                icon={menuItemIcons[index]}
                onClick={() => {
                  dispatch(setHeaderTitle(menuItem));
                  dispatch(setHeaderBackButtonVisible(false));
                }}
              >
                <NoStyleLink
                  to={index === 0 ? "/" : "/" + menuItem.toLowerCase()}
                >
                  {menuItem}
                </NoStyleLink>
              </Menu.Item>
            );
          })}
        </OptionsMenu>
        <LogoutOption icon={<LogoutIcon />} onClick={handleLogout}>
          Logout
        </LogoutOption>
      </Options>
      <ContentContainer>
        <ContentHeader>
          {backButtonVisible ? (
            <BackButton
              icon={
                <LeftOutlined
                  style={{ color: "#000000", fontSize: "1.5rem" }}
                />
              }
              onClick={() => {
                history.goBack();
                dispatch(setHeaderBackButtonVisible(false));
              }}
            />
          ) : (
            ""
          )}
          <Title>{headerTitle}</Title>
        </ContentHeader>
        <MainContent>{props.children}</MainContent>
      </ContentContainer>
    </MainDiv>
  );
};

export default AuthenticatedPageContainer;
