import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Input, Select } from "antd";

import { StyledModal } from "../styles";
import { RootState } from "../reducers";
import { setAddProjectVisible } from "../reducers/featuresSlice";
import { getAllClients } from "../api/clients";
import { ClientDataType } from "../types/ClientTypes";
import { createProject } from "../api/projects";
import { ProjectFormType } from "../types/ProjectTypes";
import { errorHandling } from "../config/addModalErrorHandling";

const { Option } = Select;
const StyledAddProjectModal = styled(StyledModal)``;
const AddProjectModal = () => {
  const [clientsList, setClientsList] = useState<ClientDataType[]>([]);
  const dispatch = useDispatch();
  const addProjectVisible = useSelector(
    (state: RootState) => state.features.addProjectVisible
  );
  const handleAddProject = (projectData: ProjectFormType) => {
    createProject(projectData)
      .then(() => {
        form.resetFields();
        dispatch(setAddProjectVisible(false));
      })
      .catch((error) => {
        errorHandling(error, form);
      });
  };

  useEffect(() => {
    getAllClients().then(({ results }) => setClientsList(results));
    return () => {
      setClientsList([]);
    };
  }, []);

  const [form] = Form.useForm();
  return (
    <StyledAddProjectModal
      centered
      visible={addProjectVisible}
      width="550px"
      title="New Project"
      okText="Add"
      cancelText="Cancel"
      onCancel={(close) => {
        dispatch(setAddProjectVisible(false));
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleAddProject(values);
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_project"
        initialValues={
          {
            /* modifier: "public" */
          }
        }
        requiredMark={false}
      >
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item
              name="name"
              label="Project name"
              rules={[
                {
                  required: true,
                  message: "Please enter project name",
                },
              ]}
            >
              <Input placeholder="Enter project name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={9} offset={2}>
            <Form.Item
              name="clientId"
              label="Client"
              rules={[
                {
                  required: true,
                  message: "Please select client",
                },
              ]}
            >
              <Select placeholder="Select client">
                {clientsList.map((client) => (
                  <Option value={client.id} key={client.id}>
                    {client.contactFirstName + " " + client.contactLastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9} offset={2}>
            <Form.Item
              name="billingMethod"
              label="Billing method"
              rules={[
                {
                  required: true,
                  message: "Please select billing method",
                },
              ]}
            >
              <Select placeholder="Select billing method">
                <Option value="FIXED">Fixed</Option>
                <Option value="HOURLY">Hourly</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item
              name="rate"
              label="Rate"
              rules={[
                {
                  required: true,
                  message: "Please enter rate",
                },
              ]}
            >
              <Input placeholder="Enter rate" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item name="description" label="Description">
              <Input.TextArea
                placeholder="Enter description"
                style={{ resize: "none" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledAddProjectModal>
  );
};

export default AddProjectModal;
