import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventDataType } from "../types/EventTypes";
import moment from "moment";

interface initialFeaturesStateProps {
  addLogVisible: boolean;
  logFilterVisible: boolean;
  addClientVisible: boolean;
  addProjectVisible: boolean;
  headerBackButtonVisible: boolean;
  sendEmailVisible: boolean;
  createEventVisible: boolean;
  eventModalOption: string;
  eventData: EventDataType;
  eventDetailsVisible: boolean;
  createTagVisible: boolean;
  tagModalOption: "Create" | "Edit";
  tagData: {
    label: string;
    color: string;
    id: string;
  };
}

const initialFeaturesState: initialFeaturesStateProps = {
  addLogVisible: false,
  logFilterVisible: false,
  addClientVisible: false,
  addProjectVisible: false,
  headerBackButtonVisible: false,
  sendEmailVisible: false,
  createEventVisible: false,
  eventModalOption: "",
  eventData: {
    id: "",
    name: "",
    description: "",
    url: undefined,
    startTimestamp: moment(),
    endTimestamp: moment(),
    reminderTimestamp: moment(),
  },
  eventDetailsVisible: false,
  createTagVisible: false,
  tagModalOption: "Create",
  tagData: {
    label: "",
    color: "",
    id: "",
  },
};
const featuresSlice = createSlice({
  name: "features",
  initialState: initialFeaturesState,
  reducers: {
    setLogModalVisible: (state, action: PayloadAction<boolean>) => {
      state.addLogVisible = action.payload;
    },
    setLogFilterVisible: (state, action: PayloadAction<boolean>) => {
      state.logFilterVisible = action.payload;
    },
    setAddClientVisible: (state, action: PayloadAction<boolean>) => {
      state.addClientVisible = action.payload;
    },
    setAddProjectVisible: (state, action: PayloadAction<boolean>) => {
      state.addProjectVisible = action.payload;
    },
    setHeaderBackButtonVisible: (state, action: PayloadAction<boolean>) => {
      state.headerBackButtonVisible = action.payload;
    },
    setSendEmailVisible: (state, action: PayloadAction<boolean>) => {
      state.sendEmailVisible = action.payload;
    },
    setCreateEventVisible: (state, action: PayloadAction<boolean>) => {
      state.createEventVisible = action.payload;
    },
    setEventModalOption: (state, action: PayloadAction<string>) => {
      state.eventModalOption = action.payload;
    },
    setEventData: (state, action: PayloadAction<EventDataType>) => {
      state.eventData = action.payload;
    },
    setEventDetailsVisible: (state, action: PayloadAction<boolean>) => {
      state.eventDetailsVisible = action.payload;
    },
    setCreateTagVisible: (state, action: PayloadAction<boolean>) => {
      state.createTagVisible = action.payload;
    },
    setTagModalOption: (
      state,
      action: PayloadAction<initialFeaturesStateProps["tagModalOption"]>
    ) => {
      state.tagModalOption = action.payload;
    },
    setTagData: (
      state,
      action: PayloadAction<initialFeaturesStateProps["tagData"]>
    ) => {
      state.tagData = action.payload;
    },
  },
});
export const {
  setLogModalVisible,
  setLogFilterVisible,
  setAddClientVisible,
  setAddProjectVisible,
  setHeaderBackButtonVisible,
  setSendEmailVisible,
  setCreateEventVisible,
  setEventModalOption,
  setEventData,
  setEventDetailsVisible,
  setCreateTagVisible,
  setTagModalOption,
  setTagData,
} = featuresSlice.actions;
export default featuresSlice.reducer;
