import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import {
  Dashboard,
  Login,
  ForgotPassword,
  InvalidRoutePage,
  Timelog,
  Clients,
  EditClient,
  Projects,
  SignUp,
  VerifyEmail,
  ProjectDetails,
  Verification,
  Calendar,
  Tags,
} from "./pages";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/timelog" component={Timelog} />
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/clients/:client_id" component={EditClient} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/verify-email/:email" component={VerifyEmail} />
        <Route exact path="/projects/:project_id" component={ProjectDetails} />
        <Route exact path="/verification" component={Verification} />
        <Route exact path="/calendar" component={Calendar} />
        <Route exact path="/tags" component={Tags} />
        <Route path="*" component={InvalidRoutePage} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
