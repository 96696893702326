import styled from "styled-components";

const FormContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    width: 25%;
    padding: 2.5% 2.5% 1.25% 2.5%;
    box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    h1,
    label {
      color: #252525;
    }
    h1 {
      font-size: 1.5rem;
    }

    @media (max-width: 1600px) and (orientation: landscape) {
      width: 30%;
    }

    @media (max-width: 1280px) and (orientation: landscape) {
      width: 40%;
    }

    @media (max-width: 720px) and (orientation: portrait) {
      width: 90%;
      padding: 10%;
    }
    @media (min-width: 720px) and (max-width: 1280px) and (orientation: portrait) {
      width: 60%;
      padding: 10%;
    }

    label {
      font-size: 1rem;
      font-weight: 700;
    }
  }
`;

export default FormContainer;
