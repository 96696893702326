import React from "react";
import styled from "styled-components";
import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Divider, Typography, Select, message } from "antd";
import { PieChart, Pie } from "recharts";

import { checkAuth, AddProjectModal } from "../components";
import { StyledTable } from "../styles";
import { EllipseIcon } from "../icons";
import { setAddProjectVisible } from "../reducers/featuresSlice";
import { setHeaderTitle } from "../reducers/authSlice";
import { getAllProjects } from "../api/projects";
import { RootState } from "../reducers";
import { ProjectDataType, ProjectTableType } from "../types/ProjectTypes";

const { Text, Title } = Typography;
const { Option } = Select;

const ProjectsDiv = styled.div``;
const AddProjectButton = styled(Button)`
  font-weight: 700;
  font-size: 1.125rem;
  height: 50px;
  margin-bottom: 1rem;
`;
const ProjectsTable = styled(StyledTable)`
  width: 100%;
  thead {
    .ant-table-cell {
      height: 3.3rem;
    }
  }
  tr {
    .ant-table-cell {
      height: 3.375rem;
    }
  }
  @media (min-width: 640px) {
    .ant-table-thead,
    .ant-table-row {
      .ant-table-cell:first-of-type {
        padding-left: 4.2%;
      }
    }
  }
  .ant-table-row {
    cursor: pointer;
  }
`;
const TimeSpentDiv = styled.div`
  margin-left: 4.2%;
  margin-top: 60px;
  padding-top: 50px;
  width: 640px;
  height: 607px;
  background: #f6faff;
  border-radius: 5px;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
const Date = styled(Text)`
  font-size: 1rem;
`;
const StyledEllipseIcon = styled(EllipseIcon)`
  margin-top: 2.5%;
  margin-right: 4%;
  circle {
    fill: ${(props) => props.fill};
  }
`;

const Projects = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const addProjectVisible = useSelector(
    (state: RootState) => state.features.addProjectVisible
  );
  // const [projects, setProjects] = useState<ProjectDataType[]>([]);
  const [formattedProjectsData, setFormattedProjectsData] = useState<
    ProjectTableType[]
  >([]);
  const [isProjectsLoading, setProjectsLoading] = useState(true);
  const [pageSize] = useState<number>(5);
  const [pageNumber, setPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const formatProjectsData = useCallback((projectsData: ProjectDataType[]) => {
    setFormattedProjectsData(
      projectsData.map(({ id, client, name, billingMethod, rate }) => {
        let formattedRate = "$" + String(rate);
        const formattedBillingMethod =
          billingMethod[0] + billingMethod.substring(1).toLowerCase();
        return {
          id,
          clientName: client.contactFirstName + " " + client.contactLastName,
          project: name,
          billingMethod: formattedBillingMethod,
          rate: formattedRate,
        };
      })
    );
    setProjectsLoading(false);
  }, []);
  const handlePagination = (page: number) => {
    setPage(page);
  };
  useEffect(() => {
    dispatch(setHeaderTitle("Projects"));
  });
  useEffect(() => {
    getAllProjects()
      .then((response) => {
        setTotalElements(response.totalElements);
        formatProjectsData(response.results);
      })
      .catch((error) => {
        message.error(error.response.data.message);
        setProjectsLoading(false);
      });
  }, [formatProjectsData, pageNumber, pageSize, addProjectVisible]);

  const handleRowClickChange = (
    record: object,
    rowIndex: number | undefined
  ) => {
    history.push("/projects/" + String((rowIndex || 0) + 1), { data: record });
  };
  const columns = [
    {
      key: "clientName",
      title: "Client name",
      dataIndex: "clientName",
    },
    {
      key: "project",
      title: "Project",
      dataIndex: "project",
    },
    {
      key: "billingMethod",
      title: "Billing method",
      dataIndex: "billingMethod",
    },
    {
      key: "rate",
      title: "Rate",
      dataIndex: "rate",
    },
  ];

  const timeData = [
    {
      project: "Time log app",
      value: 20,
      fill: "#1F4DA6",
    },
    {
      project: "Appointment app",
      value: 30,
      fill: "#02B2A7",
    },
    {
      project: "Delivery app",
      value: 20,
      fill: "#0B6666",
    },
    {
      project: "Ecommerce app",
      value: 30,
      fill: "#39276B",
    },
  ];
  return (
    <ProjectsDiv>
      <Row>
        <Col span={4} offset={1}>
          <AddProjectButton
            type="primary"
            onClick={() => {
              dispatch(setAddProjectVisible(true));
            }}
          >
            + Add project
          </AddProjectButton>
          <AddProjectModal />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          border: "1px solid #d0d0d0",
        }}
      />
      <ProjectsTable
        loading={isProjectsLoading}
        columns={columns}
        dataSource={formattedProjectsData}
        pagination={{
          position: ["bottomCenter"],
          onChange: handlePagination,
          current: pageNumber,
          pageSize,
          total: totalElements,
        }}
        scroll={{ x: "100%" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleRowClickChange(record, rowIndex);
            },
          };
        }}
      />
      <TimeSpentDiv>
        <Row>
          <Col span={10} offset={2}>
            <Title
              level={2}
              style={{
                fontSize: "1.5rem",
                fontWeight: 700,
                marginBottom: "1em",
              }}
            >
              Time spent on projects
            </Title>
            <Date>1 July, 2020</Date>
          </Col>
          <Col span={10} offset={2}>
            <Title
              level={3}
              style={{
                fontSize: "1rem",
                fontWeight: 400,
                marginTop: "0.5em",
                marginBottom: "1.2em",
              }}
            >
              Select time period
            </Title>
            <Select defaultValue="Today" style={{ width: "75%" }}>
              <Option value="day">Today</Option>
              <Option value="week">This Week</Option>
              <Option value="month">This Month</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 16, offset: 4 }}>
            <PieChart
              height={300}
              width={200}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pie
                data={timeData}
                dataKey="value"
                nameKey="project"
                innerRadius={60}
                outerRadius={80}
              />
            </PieChart>
          </Col>
        </Row>
        <Row>
          {timeData.map((data, index) => {
            return (
              <Col
                key={index}
                span={6}
                offset={2}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "10px 0",
                }}
              >
                <StyledEllipseIcon fill={data.fill} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>{data.project}</span>
                  <span>{data.value}%</span>
                </div>
              </Col>
            );
          })}
        </Row>
      </TimeSpentDiv>
    </ProjectsDiv>
  );
};

export default checkAuth(Projects);
