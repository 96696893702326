import React from "react";
import styled from "styled-components";
import { Typography, Image } from "antd";
import { email2 } from "../images";
import { StyledButton } from "../styles";
import { Login } from "../pages";

const { Title, Text } = Typography;
const ContainerDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const VerifyEmailDiv = styled.div`
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.14);
  text-align: center;
  padding: 3rem;
  border-radius: 5px;
  h1.ant-typography {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;

    color: #12a500;
    text-align: center;
  }
  span.ant-typography {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #777;
    align-items: center;
  }
  img.ant-image-img {
    width: 125px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-right: 30px;
  }
`;

const LoginButton = styled(StyledButton)`
  width: 100%;
  margin-top: 4rem;
  background-color: #0029ff;
  border-radius: 5px;
  span {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
  }
`;

const VerificationSuccess = () => {
  return (
    <ContainerDiv>
      <VerifyEmailDiv>
        <Title>Verification Success !</Title>
        <Text>
          Your email has been confirmed. You can <br />
          now login
        </Text>
        <div>
          <Image
            alt="email_icon1"
            width={92.5}
            height={92.5}
            preview={false}
            src={email2}
          />
        </div>
        <LoginButton
          onClick={Login}
          type="primary"
          htmlType="submit"
          size="large"
        >
          Go to Login
        </LoginButton>
      </VerifyEmailDiv>
    </ContainerDiv>
  );
};

export default VerificationSuccess;
