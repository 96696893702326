import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, DatePicker, Row, Col, TimePicker } from "antd";

import { RootState } from "../reducers";
import { setLogModalVisible } from "../reducers/featuresSlice";
import { StyledModal } from "../styles";

const StyledTimeLogModal = styled(StyledModal)`
  #time_fields_seperator {
    display: flex;
    justify-content: center;
    align-items: self-end;
  }
`;
const TimeLogModal = () => {
  const addLogVisible = useSelector(
    (state: RootState) => state.features.addLogVisible
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  return (
    <StyledTimeLogModal
      visible={addLogVisible}
      title="New Log Entry"
      okText="Save"
      cancelText="Cancel"
      onCancel={() => {
        dispatch(setLogModalVisible(false));
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            console.log(values);
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_log"
        initialValues={
          {
            /* modifier: "public" */
          }
        }
        requiredMark={false}
      >
        <Row gutter={[0, 15]}>
          <Col span={9} offset={2}>
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please select date",
                },
              ]}
              wrapperCol={{
                span: 24,
              }}
            >
              <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={9} offset={2}>
            <Form.Item
              name="project_name"
              label="Project Name"
              rules={[
                {
                  required: true,
                  message: "Enter project name",
                },
              ]}
            >
              <Input placeholder="Enter project name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item
              name="task_name"
              label="Task Name"
              rules={[
                {
                  required: true,
                  message: "Please enter task name",
                },
              ]}
            >
              <Input placeholder="Enter task name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item name="tags" label="Tags">
              <Input placeholder="Enter tag" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item name="description" label="Description">
              <Input.TextArea
                placeholder="Enter description"
                style={{ resize: "none" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={7} offset={2}>
            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[
                {
                  required: true,
                  message: "Select start time",
                },
              ]}
            >
              <TimePicker
                format="h:mm a"
                use12Hours
                minuteStep={5}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col id="time_fields_seperator" span={2} offset={2}>
            <Form.Item style={{ fontSize: "0.875rem" }}>to</Form.Item>
          </Col>
          <Col span={7} offset={2}>
            <Form.Item
              name="endTime"
              label="End Time"
              rules={[
                {
                  required: true,
                  message: "Select end time",
                },
              ]}
            >
              <TimePicker
                use12Hours
                format="h:mm a"
                minuteStep={5}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledTimeLogModal>
  );
};

export default TimeLogModal;
