import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import {
  Form,
  Input,
  Radio,
  Row,
  Col,
  DatePicker,
  Button,
  Image,
  Space,
  Select,
} from "antd";

import { RootState } from "../reducers";
import { setSendEmailVisible } from "../reducers/featuresSlice";
import { StyledModal } from "../styles";
import { sendEmailImg } from "../images";

const { Option } = Select;
const { RangePicker } = DatePicker;
const StyledSendEmailModal = styled(StyledModal)`
  .ant-modal-footer {
    margin-left: 8.4%;
    .ant-radio-wrapper {
      display: flex;
    }
  }
`;
const SendEmailModal = () => {
  const sendEmailVisible = useSelector(
    (state: RootState) => state.features.sendEmailVisible
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [customPeriod, isCustomPeriod] = useState<boolean>(false);
  const handleRadioOptionChange = (e: any) => {
    if (e.target.value === "customTimePeriod") {
      isCustomPeriod(true);
    } else {
      isCustomPeriod(false);
    }
  };
  return (
    <StyledSendEmailModal
      visible={sendEmailVisible}
      title="Send email"
      onCancel={() => {
        dispatch(setSendEmailVisible(false));
      }}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                console.log(values);
              })
              .catch(() => {});
          }}
          style={{ width: "100%" }}
        >
          Send email
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="send_email"
        initialValues={
          {
            /* modifier: "public" */
          }
        }
        requiredMark={false}
      >
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <span>
              An email will be sent to the client with time logs in the selected
              time period
            </span>
          </Col>
        </Row>
        <Row gutter={[0, 15]} style={{ marginTop: "5%", marginBottom: "5%" }}>
          <Col span={8} offset={8}>
            <Image alt="send_email_image" preview={false} src={sendEmailImg} />
          </Col>
        </Row>

        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item
              name="clientEmail"
              label="Client email"
              rules={[
                {
                  required: true,
                  message: "Please enter client email",
                },
                {
                  type: "email",
                  message: "Please enter valid email",
                },
              ]}
            >
              <Input placeholder="Enter client email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item name="message" label="Message">
              <Input.TextArea
                placeholder="Enter message"
                style={{ resize: "none" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="timePeriodType">
          <Row gutter={[0, 15]}>
            <Col span={20} offset={2}>
              <Radio.Group
                defaultValue="timePeriod"
                onChange={handleRadioOptionChange}
              >
                <Space direction="vertical">
                  <Radio value="timePeriod">
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Form.Item
                        name="timePeriod"
                        label="Time Period"
                        rules={[
                          {
                            required: !customPeriod,
                            message: "Select  time period",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select time period"
                          style={{ width: "250px" }}
                          disabled={customPeriod}
                        >
                          <Option value="day">Today</Option>
                          <Option value="week">This Week</Option>
                          <Option value="month">This Month</Option>
                        </Select>
                      </Form.Item>
                    </span>
                  </Radio>
                  <Radio value="customTimePeriod">
                    <Form.Item
                      name="customTimePeriod"
                      label="Custom Time Period"
                      rules={[
                        {
                          required: customPeriod,
                          message: "Select custom time period",
                        },
                      ]}
                    >
                      <RangePicker
                        disabled={!customPeriod}
                        style={{ width: "250px" }}
                      />
                    </Form.Item>
                  </Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </StyledSendEmailModal>
  );
};

export default SendEmailModal;
