import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Select,
  InputNumber,
  message,
} from "antd";
import moment from "moment";

import { StyledModal } from "../styles";
import { RootState } from "../reducers";
import { setCreateEventVisible } from "../reducers/featuresSlice";
import { createEvent, editEvent } from "../api/events";
import { EventFormType } from "../types/EventTypes";
import { ErrorObjectType } from "../types";

const StyledCreateEventModal = styled(StyledModal)`
  .ant-modal-title {
    font-family: Roboto;
  }
  .ant-radio-wrapper {
    padding: 0px 20px;
  }
  .ant-radio-wrapper:first-of-type {
    padding-left: 0px;
  }
`;

const CreateEventModal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const createEventVisible = useSelector(
    (state: RootState) => state.features.createEventVisible
  );
  const option = useSelector(
    (state: RootState) => state.features.eventModalOption
  );
  const eventData = useSelector((state: RootState) => state.features.eventData);
  useEffect(() => {
    if (option === "Edit") {
      //Setting initial form data using event data supplied by api if edit option of modal is opened
      const formData: EventFormType = {
        eventDate: [moment(), moment()],
        eventTime: [moment(), moment()],
        reminderTimeValue: 0,
        reminderTimeOption: "minutes",
        ...eventData,
      };
      formData.eventDate[0] = moment(eventData.startTimestamp);
      formData.eventTime[0] = moment(eventData.startTimestamp);
      formData.eventDate[1] = moment(eventData.endTimestamp);
      formData.eventTime[1] = moment(eventData.endTimestamp);
      if (
        eventData.reminderTimestamp !== undefined &&
        eventData.reminderTimestamp !== null
      ) {
        const reminderTime = moment(eventData.endTimestamp).diff(
          eventData.reminderTimestamp,
          "minutes"
        );
        console.log(reminderTime);
        if (reminderTime >= 1440) {
          formData.reminderTimeOption = "days";
          formData.reminderTimeValue = reminderTime / 1440;
        } else if (reminderTime >= 60) {
          formData.reminderTimeOption = "hours";
          formData.reminderTimeValue = reminderTime / 60;
        } else {
          formData.reminderTimeOption = "minutes";
          formData.reminderTimeValue = reminderTime;
        }
      } else {
        formData.reminderTimeValue = undefined;
        formData.reminderTimeOption = undefined;
      }
      // console.log(formData);
      form.setFieldsValue(formData);
    } else {
      form.resetFields();
    }
  }, [form, eventData, option, createEventVisible]); //called if modal visibility changed, option changed, incoming event data changes or form updated
  const handleEventPostErrors = (error: any) => {
    if (error.response.data.message) {
      message.error(error.response.data.message);
    }
    const requestErrors = error.response.data.errors;
    const validationErrors: ErrorObjectType = {};
    if (requestErrors !== undefined || requestErrors !== null) {
      requestErrors.forEach((error: { param: string; msg: string }) => {
        if (!validationErrors[error.param]) {
          validationErrors[error.param] = [];
        }
        validationErrors[error.param].push(error.msg);
        validationErrors["startTimestamp"] = validationErrors["eventDate"]; //If an error is present in startTimestamp, it is renamed to eventDate as that is the name used in the Form
        validationErrors["reminderTimestamp"] =
          validationErrors["reminderTimeValue"]; //If an error is present in reminderTimestamp, it is renamed to remainderfieldOption as that is the name used in the Form
        form.setFields([
          {
            name: error.param,
            errors: validationErrors[error.param],
          },
        ]);
      });
    }
  };
  return (
    <StyledCreateEventModal
      centered
      visible={createEventVisible}
      width="605px"
      title={`${option} event`}
      okText="Save"
      cancelText="Cancel"
      onCancel={(close) => {
        dispatch(setCreateEventVisible(false));
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((eventFormData: EventFormType) => {
            console.log(eventFormData.reminderTimeOption);
            const startTime = eventFormData.eventTime[0].format("HH:mm:ss"); //Convert dates and times from moment to normal format
            const endTime = eventFormData.eventTime[1].format("HH:mm:ss");
            const startDate = eventFormData.eventDate[0].format("YYYY-MM-DD");
            const endDate = eventFormData.eventDate[1].format("YYYY-MM-DD");
            const startTimestamp = moment(startDate + " " + startTime); //Combine converted time and start date to create a timestamp
            const endTimestamp = moment(endDate + " " + endTime);
            const url =
              eventFormData.url !== undefined &&
              String(eventFormData.url).length === 0
                ? undefined
                : eventFormData.url; //Checks edge case where user first enters a url, then clears it resulting in a "" value for url.Converts this value to undefined
            let reminderTimestamp;
            if (
              eventFormData.reminderTimeValue !== null &&
              eventFormData.reminderTimeOption !== undefined &&
              eventFormData.reminderTimeValue !== 0 &&
              endTimestamp !== undefined
            ) {
              //If neither of the reminder time fields is undefined or if None select option is not selected, sets reminderTimeStamp, else passes null
              reminderTimestamp = endTimestamp
                .clone()
                .subtract(
                  eventFormData.reminderTimeValue,
                  eventFormData.reminderTimeOption
                );
            }
            const postData = {
              name: eventFormData.name,
              description: eventFormData.description,
              url: url,
              startTimestamp,
              endTimestamp,
              reminderTimestamp: reminderTimestamp,
            };
            if (option === "Create") {
              //When new event has to be created
              createEvent(postData)
                .then(() => {
                  form.resetFields();
                  dispatch(setCreateEventVisible(false));
                })
                .catch((error) => {
                  handleEventPostErrors(error);
                });
            } else {
              editEvent(eventData.id, postData)
                .then(() => dispatch(setCreateEventVisible(false)))
                .catch((error) => handleEventPostErrors(error));
            }
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name={`${option}_event`}
        requiredMark={false}
        initialValues={eventData}
      >
        <Row>
          <Col span={20} offset={2}>
            <Form.Item
              name="name"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Enter event title",
                },
              ]}
            >
              <Input placeholder="Enter title" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <Form.Item name="description" label="Description">
              <Input.TextArea
                placeholder="Enter description"
                rows={3}
                style={{ resize: "none" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <Form.Item
              name="url"
              label="URL"
              rules={[
                {
                  type: "url",
                  message: "Enter valid URL",
                },
              ]}
            >
              <Input placeholder="Enter URL" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10} offset={2}>
            <Form.Item
              name="eventDate"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Please enter event date",
                },
              ]}
            >
              <DatePicker.RangePicker />
            </Form.Item>
          </Col>
          <Col span={9} offset={1}>
            <Form.Item
              name="eventTime"
              label="Time"
              rules={[
                {
                  required: true,
                  message: "Please enter event time",
                },
              ]}
            >
              <TimePicker.RangePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5} offset={2}>
            <Form.Item
              name="reminderTimeValue"
              label="Reminder time"
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 24 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                placeholder="Enter time"
              />
            </Form.Item>
          </Col>
          <Col span={5} offset={0}>
            <Form.Item
              name="reminderTimeOption"
              label={<label style={{ color: "#FFFFFF" }}>dummy</label>}
            >
              <Select placeholder="Select type">
                <Select.Option value="minutes">minutes</Select.Option>
                <Select.Option value="hours">hours</Select.Option>
                <Select.Option value="days">days</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledCreateEventModal>
  );
};

export default CreateEventModal;
