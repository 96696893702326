import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Form, Input, Typography, Button } from "antd";
import { FormContainer } from "../styles";
import { Link } from "react-router-dom";

const { Title } = Typography;
const LoginDiv = styled(FormContainer)`
  .ant-row:nth-of-type(5) {
    text-align: center;
  }
  #login-form_title {
    text-align: center;
    font-weight: 400;
  }
`;

const SignUpButton = styled(Button)`
  width: 100%;
  span {
    font-weight: 700;
  }
`;

const LoginPage = styled(Link)`
  font-size: 1rem;
  font-weight: 400 bold;
  color: black;
  text-align: center;
`;

const SignUp = () => {
  const history = useHistory();
  return (
    <LoginDiv>
      <Form
        name="login-form"
        layout="vertical"
        size="large"
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={({ email }) => {
          history.push(`/verify-email/${email}`);
        }}
      >
        <Form.Item name="title">
          <Title>Sign up</Title>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter an email!",
            },
            {
              type: "email",
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <SignUpButton type="primary" htmlType="submit" size="large">
            Continue
          </SignUpButton>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <div>
            Already have an account? <LoginPage to="/login">Login</LoginPage>
          </div>
        </Form.Item>
      </Form>
    </LoginDiv>
  );
};
export default SignUp;
