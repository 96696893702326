import React from "react";
import styled from "styled-components";
import { Typography, Image } from "antd";
import { email1 } from "../images";
import { useParams } from "react-router";

const { Title, Text } = Typography;
const ContainerDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const VerifyEmailDiv = styled.div`
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.14);
  text-align: center;
  padding: 3rem;
  border-radius: 5px;
  h1.ant-typography {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;

    color: #222;
    text-align: center;
  }
  span.ant-typography {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #777;
    align-items: center;
  }
  img.ant-image-img {
    width: 125px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-right: 30px;
  }
`;

const VerifyEmail = () => {
  interface ParamTypes {
    email: string;
  }
  const { email } = useParams<ParamTypes>();
  return (
    <ContainerDiv>
      <VerifyEmailDiv>
        <Title>Verify Email</Title>
        <Text>
          Please check your email ({email})<br /> to confirm your account
        </Text>
        <div>
          <Image
            alt="email_icon1"
            width={92.5}
            height={92.5}
            preview={false}
            src={email1}
          />
        </div>
      </VerifyEmailDiv>
    </ContainerDiv>
  );
};

export default VerifyEmail;
