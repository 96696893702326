import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Button, Row, Col, Divider, message } from "antd";

import { checkAuth, AddClientModal } from "../components";
import { StyledTable } from "../styles";
import { setHeaderTitle } from "../reducers/authSlice";
import { setAddClientVisible } from "../reducers/featuresSlice";
import { RootState } from "../reducers";
import { ClientDataType, ClientResponseType } from "../types/ClientTypes";
import { getAllClients } from "../api/clients";

const ClientsDiv = styled.div``;
const AddClientButton = styled(Button)`
  font-weight: 700;
  font-size: 1.125rem;
  height: 49px;
  margin-bottom: 1rem;
`;
const ClientsTable = styled(StyledTable)`
  width: 100%;
  tr {
    .ant-table-cell {
      height: 3.75rem;
    }
  }
  .ant-table-row {
    cursor: pointer;
  }
`;

const Clients = () => {
  const addClientVisible = useSelector(
    (state: RootState) => state.features.addClientVisible
  );
  const dispatch = useDispatch();
  const [clients, setClients] = useState<ClientResponseType>({
    pageNumber: 0,
    pageSize: 0,
    results: [],
    totalElements: 0,
    totalPages: 0,
  });
  const [tableLoading, isLoading] = useState<boolean>(true);
  const [pageSize] = useState<number>(5);
  const [pageNumber, setPage] = useState<number>(1);
  const handlePagination = (page: number) => {
    setPage(page);
  };
  useEffect(() => {
    dispatch(setHeaderTitle("Clients"));
    getAllClients(pageSize, pageNumber)
      .then((data) => {
        setClients(data);
        isLoading(false);
      })
      .catch((error) => {
        message.error(error.response.data.message);
        isLoading(false);
      });
  }, [dispatch, addClientVisible, pageNumber, pageSize]);
  const history = useHistory();
  const data = clients.results.map((res: ClientDataType) => {
    return {
      contactName: res.contactFirstName + " " + res.contactLastName,
      companyName: res.companyName,
      email: res.email,
      phone: res.phone,
    };
  });
  const handleRowSelectChange = (rowKeys: any, rows: any) => {};
  const handleRowClickChange = (record: any, rowIndex: any) => {
    history.push("/clients/" + String(rowIndex + 1), {
      client: clients.results[rowIndex],
    });
  };
  const columns = [
    {
      key: "contactName",
      title: "Contact Name",
      dataIndex: "contactName",
    },
    {
      key: "companyName",
      title: "Company Name",
      dataIndex: "companyName",
    },
    {
      key: "email",
      title: "Contact Email",
      dataIndex: "email",
    },
    {
      key: "phone",
      title: "Contact Phone",
      dataIndex: "phone",
    },
  ];
  return (
    <ClientsDiv>
      <Row>
        <Col span={4} offset={1}>
          <AddClientButton
            type="primary"
            onClick={() => {
              dispatch(setAddClientVisible(true));
            }}
          >
            + Add client
          </AddClientButton>
          <AddClientModal />
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0px",
          border: "1px solid #d0d0d0",
        }}
      />
      <ClientsTable
        loading={tableLoading}
        rowSelection={{
          type: "checkbox",
          onChange: handleRowSelectChange,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleRowClickChange(record, rowIndex);
            },
          };
        }}
        columns={columns}
        dataSource={data}
        pagination={{
          position: ["bottomCenter"],
          onChange: handlePagination,
          current: pageNumber,
          pageSize,
          total: clients.totalElements,
        }}
        scroll={{ x: "100%" }}
      />
    </ClientsDiv>
  );
};

export default checkAuth(Clients);
