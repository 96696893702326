import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, DatePicker, Select } from "antd";

import { RootState } from "../reducers";
import { setLogFilterVisible } from "../reducers/featuresSlice";
import { StyledModal } from "../styles";

const { Option } = Select;
const StyledLogFilterModal = styled(StyledModal)`
  .ant-modal-title {
    font-family: Nunito;
  }
  .ant-modal-close,
  .ant-modal-title {
    padding-top: 30px;
  }
  .ant-modal-footer {
    padding-bottom: 30px;
    .ant-btn,
    .ant-btn-hover,
    .ant-btn-focus {
      background: #ffffff;
      border: 1px solid #2b6ff2;
      color: #2b6ff2;
    }
    .ant-btn-primary {
      background: #2b6ff2;
      color: #ffffff;
    }
  }
  .ant-select-selector {
    border: 1px solid #aaaaaa !important;
  }
`;

const LogFilterModal = () => {
  const logFilterVisible = useSelector(
    (state: RootState) => state.features.logFilterVisible
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  return (
    <StyledLogFilterModal
      centered
      visible={logFilterVisible}
      width="382px"
      title="Filter by"
      okText="Apply"
      cancelText="Clear"
      onCancel={(close) => {
        if (String(close.currentTarget.className) === "ant-btn") {
          form.resetFields();
        } else {
          dispatch(setLogFilterVisible(false));
        }
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            console.log(values);
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="filter_log"
        initialValues={
          {
            /* modifier: "public" */
          }
        }
        requiredMark={false}
      >
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item name="project_filter" label="Project">
              <Select placeholder="Select Project">
                <Option value="Project A">Project A</Option>
                <Option value="Project B">Project B</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item name="task_filter" label="Task">
              <Select placeholder="Select Task">
                <Option value="Task A">Task A</Option>
                <Option value="Task B">Task B</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item name="timeperiod_filter" label="Time Period">
              <DatePicker.RangePicker
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledLogFilterModal>
  );
};

export default LogFilterModal;
