import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Row, Col, Divider } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { checkAuth } from "../components";
import { FilterIcon } from "../icons";
import {
  setLogModalVisible,
  setLogFilterVisible,
} from "../reducers/featuresSlice";
import { TimeLogModal, LogFilterModal } from "../components";
import { StyledTable } from "../styles";
import { setHeaderTitle } from "../reducers/authSlice";

interface TimeLogDataProps {
  key: number;
  id: string;
  date: string;
  project: string;
  task: string;
  description: string;
  tags: string;
  time: string;
}
const TimeLogDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
const OptionsRow = styled(Row)`
  height: 40px;
  .ant-btn {
    background-color: #eff5ff;
    border-radius: 5px;
    border-color: #ffffff;
  }
`;
const AddTimeLog = styled(Row)`
  margin: 16px 0;
  button {
    font-weight: 700;
    font-size: 1.125rem;
    height: 49px;
  }
`;
const TimeLogTable = styled(StyledTable)``;

const Timelog = () => {
  useEffect(() => {
    dispatch(setHeaderTitle("Timelog"));
  });
  const dispatch = useDispatch();
  const columns = [
    {
      key: "id",
      title: "Sl no.",
      dataIndex: "id",
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "project_name",
      title: "Project",
      dataIndex: "project",
    },
    {
      key: "task_name",
      title: "Task",
      dataIndex: "task",
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "tags",
      title: "Tags",
      dataIndex: "tags",
    },
    {
      key: "time",
      title: "Time",
      dataIndex: "time",
    },
  ];

  const data: TimeLogDataProps[] = [
    {
      key: 1,
      id: "1",
      date: "20/07/2021",
      project: "Time Log",
      task: "Login",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: "UI Design",
      time: "1:00:00",
    },
  ];

  const handleSelectChange = (rowKeys: any, rows: any) => {
    console.log(rows, rowKeys);
  };
  return (
    <TimeLogDiv>
      <OptionsRow>
        <Col span={1} offset={1}>
          <Button
            size="large"
            icon={<DeleteOutlined style={{ color: "#2B6FF2" }} />}
          />
        </Col>
        <Col span={1} offset={20}>
          <Button
            size="large"
            icon={<FilterIcon />}
            onClick={() => dispatch(setLogFilterVisible(true))}
          />
          <LogFilterModal />
        </Col>
      </OptionsRow>
      <AddTimeLog align="middle">
        <Col span={23} offset={1}>
          <Button
            onClick={() => {
              dispatch(setLogModalVisible(true));
            }}
            type="primary"
            size="large"
          >
            + Add time log
          </Button>
          <TimeLogModal />
        </Col>
      </AddTimeLog>
      <Divider
        style={{
          border: "1px solid #D0D0D0",
          margin: "0px",
          width: "100%",
        }}
      />
      <TimeLogTable
        rowSelection={{
          type: "checkbox",
          onChange: handleSelectChange,
        }}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: "100%" }}
      />
    </TimeLogDiv>
  );
};

export default checkAuth(Timelog);
