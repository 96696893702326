import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Row, Col } from "antd";
import { EditOutlined, DeleteOutlined, BellOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

import { RootState } from "../reducers";
import {
  setCreateEventVisible,
  setEventModalOption,
  setEventDetailsVisible,
} from "../reducers/featuresSlice";
import { deleteEvent } from "../api/events";
import moment from "moment";

const StyledEventDetailsModal = styled(Modal)`
  height: 180px;
  .ant-modal-header {
    border-bottom: 0px;
  }
  .ant-modal-close {
    padding-top: 1%;
    color: #000000;
  }
  border-radius: 5px;
  .ant-row {
    padding: 1% 0;
  }
  .ant-row:last-of-type {
    padding-top: 2.5%;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
`;
const EventModalHeader = styled.div`
  position: relative;
  left: 75%;
  button {
    border-style: none;
    box-shadow: none;
  }
`;

const EventDetailsModal = () => {
  const [reminderTimeString, setReminderString] = useState<string>();
  const eventData = useSelector((state: RootState) => state.features.eventData);
  useEffect(() => {
    if (eventData.reminderTimestamp !== null) {
      const reminderTime = moment(eventData.endTimestamp).diff(
        eventData.reminderTimestamp,
        "minutes"
      );
      if (reminderTime >= 1440) {
        setReminderString(`${reminderTime / 1440} days before`);
      } else if (reminderTime >= 60) {
        setReminderString(`${reminderTime / 60} hours before`);
      } else {
        setReminderString(`${reminderTime} minutes before`);
      }
    }
  }, [eventData]);
  const dispatch = useDispatch();
  const eventDetailsVisible = useSelector(
    (state: RootState) => state.features.eventDetailsVisible
  );
  return (
    <StyledEventDetailsModal
      centered
      mask={false}
      visible={eventDetailsVisible}
      footer={null}
      width={450}
      onCancel={() => {
        dispatch(setEventDetailsVisible(false));
      }}
      zIndex={9}
      title={
        <EventModalHeader>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              dispatch(setCreateEventVisible(true));
              dispatch(setEventModalOption("Edit"));
              dispatch(setEventDetailsVisible(false));
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              deleteEvent(eventData.id).then(() => {
                dispatch(setEventDetailsVisible(false));
              });
            }}
          />
        </EventModalHeader>
      }
    >
      <Row>
        <Col
          span={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "16px",
              height: "16px",
              background: "#2B6FF2",
              borderRadius: "5px",
            }}
          ></div>
        </Col>
        <Col span={16} style={{ fontSize: "1.125rem", fontWeight: 600 }}>
          {eventData.name}
        </Col>
      </Row>
      <Row>
        <Col span={22} offset={2}>
          {eventData.startTimestamp.format("dddd, D MMMM hh:mm a")} -{" "}
          {eventData.startTimestamp.diff(eventData.endTimestamp, "days") !==
            0 && eventData.endTimestamp.format("dddd, D MMMM ")}
          {eventData.endTimestamp.format("hh:mm a")}
        </Col>
      </Row>
      {eventData.reminderTimestamp !== null && (
        <Row>
          <Col
            span={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BellOutlined style={{ fontSize: "1.25rem" }} />
          </Col>
          <Col span={16}>{reminderTimeString}</Col>
        </Row>
      )}
    </StyledEventDetailsModal>
  );
};

export default EventDetailsModal;
