import styled from "styled-components";
import { Button } from "antd";

const StyledButton = styled(Button)`
  background: #0029ff;
  border: 2px solid #0029ff;
  border-radius: 3px;
`;

export default StyledButton;
