import styled from "styled-components";
import { Typography } from "antd";

const { Text } = Typography;
export const HomeDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  @media (max-width: 700px) {
    flex-direction: column;
  }

  /* justify-content: space-evenly; */
  /* align-items: center; */
`;
export const DateCardyellow = styled.div`
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffde7;
  width: 305px;
  height: 120px;
  border-radius: 10px;
`;
export const DateCardgreen = styled.div`
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5fff4;
  width: 305px;
  height: 120px;
  border-radius: 10px;
`;
export const DateCardpink = styled.div`
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff5fb;
  width: 305px;
  height: 120px;
  border-radius: 10px;
`;
export const Content = styled(Text)`
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;
export const ContentSub = styled(Text)`
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
`;

export const Hours = styled(Text)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  float: left;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #777777;
`;

export const Days = styled(Text)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #777777;
`;

export const SubHead = styled.div`
  width: 60%;
  @media (max-width: 700px) {
    margin: auto;
  }
`;
export const SubHead1 = styled.div`
  padding-left: 20px;
  @media (max-width: 700px) {
    margin: auto;
  }
`;
export const DateText = styled(Text)`
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
`;

export const GraphHead = styled.div`
  @media (max-width: 700px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;
export const WorkHours = styled(Text)`
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
`;
export const Center = styled.div`
  align-items: center;
  text-align: center;
`;
export const GraphBox = styled.div`
  margin-top: 20px;
  width: 100%;
  float: left;
  min-height: 400px;
  background: #f7faff;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  @media (max-width: 700px) {
    margin: auto;
    width: 100%;
  }
`;
