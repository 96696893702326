import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import { Redirect } from "react-router";

import AuthenticatedPageContainer from "./AuthenticatedPageContainer";

const checkAuth = (Component: React.FC) => {
  // Higher Order Component that only displays component(that it takes as input) if it is authenticated
  const AuthRoute = () => {
    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    if (isAuthenticated) {
      return (
        <AuthenticatedPageContainer>
          {" "}
          <Component />
        </AuthenticatedPageContainer>
      );
    }
    return <Redirect to="/login" />;
  };
  return AuthRoute;
};

export default checkAuth;
