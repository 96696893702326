import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  .ant-modal-close {
    padding-top: 40px;
    padding-right: 8.2%;
    color: #000000;
  }
  .ant-modal-header {
    border-bottom: 0px;
  }
  .ant-modal-footer {
    border-top: 0px;
    padding-bottom: 45px;
    padding-right: 12.6%;
    padding-top: 0px;
    .ant-btn,
    .ant-btn-hover,
    .ant-btn-focus {
      background: #e4e4e4;
      border: 0px;
      color: #000000;
      height: 40px;
      width: 100px;
      font-weight: 700;
    }
    .ant-btn-primary {
      background: #2b6ff2;
      color: #ffffff;
      width: 89px;
    }
  }
  .ant-modal-title {
    padding-top: 45px;
    padding-left: 8.2%;
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 700;
    color: #000000;
  }
  .ant-picker,
  .ant-input {
    border: 1px solid #aaaaaa;
  }
  .ant-form-item-label {
    label {
      font-size: 1rem;
      font-weight: 700;
      color: #000000;
    }
  }
`;

export default StyledModal;
