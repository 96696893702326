import axios from "axios";
import { store } from "../reducers";

const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const setToken = () => {
  const state = store.getState();
  if (state.auth.authToken !== "") {
    apiAxios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${state.auth.authToken}`;
  } else {
    delete apiAxios.defaults.headers.common["Authorization"];
  }
};
store.subscribe(setToken);

export default apiAxios;
