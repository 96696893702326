import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Empty, Button, Row, Col } from "antd";

import { checkAuth, CreateTagModal } from "../components";
import { noDataImage } from "../images";
import {
  setCreateTagVisible,
  setTagModalOption,
  setTagData,
} from "../reducers/featuresSlice";
import { getAllTags } from "../api/tags";
import { TagDataType } from "../types/TagTypes";
import { RootState } from "../reducers";

const TagsEmpty = styled(Empty)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  div {
    padding: 1% 0;
  }
  .ant-empty-footer {
    div {
      padding: 5% 0;
    }
  }
`;
const TagsContainer = styled.div``;
const TagsList = styled(Row)`
  min-height: 150px;
  background: #edf3ff;
  width: 90%;
  margin-left: 4.2%;
  margin-top: 2.5%;
  border-radius: 10px;
`;
const Tag = styled(Col)`
  min-height: 37px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  cursor: pointer;
`;

const Tags = () => {
  const dispatch = useDispatch();
  const createTagVisible = useSelector(
    (state: RootState) => state.features.createTagVisible
  );
  const [tags, setTags] = useState<TagDataType[]>([]);
  useEffect(() => {
    setTimeout(() => {
      getAllTags().then((response) => setTags(response.results));
    }, 500);
  }, [createTagVisible]);
  return (
    <>
      {tags.length !== 0 ? (
        <TagsContainer>
          <Row>
            <Col
              xs={{ span: 8, offset: 1 }}
              sm={{ span: 6, offset: 1 }}
              lg={{ span: 3, offset: 1 }}
            >
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                onClick={() => {
                  dispatch(setCreateTagVisible(true));
                  dispatch(setTagModalOption("Create"));
                }}
              >
                Create Tag
              </Button>
              <CreateTagModal />
            </Col>
          </Row>
          <TagsList align="middle">
            {tags.map((tag) => {
              return (
                <Tag
                  key={tag.id}
                  xs={{ span: 6, offset: 1 }}
                  lg={{ span: 3, offset: 2 }}
                  style={{ background: tag.color }}
                  onClick={() => {
                    dispatch(setCreateTagVisible(true));
                    dispatch(setTagModalOption("Edit"));
                    dispatch(setTagData(tag));
                  }}
                >
                  {tag.label}
                </Tag>
              );
            })}
          </TagsList>
        </TagsContainer>
      ) : (
        <TagsEmpty
          image={noDataImage}
          imageStyle={{ height: "191px", width: "196px" }}
          description={
            <span style={{ fontSize: "1.125rem" }}>No tags to show</span>
          }
        >
          <Button type="primary" size="large" style={{ width: "250px" }}>
            Create default tags
          </Button>
          <div>or</div>
          <Button
            size="large"
            style={{ width: "250px", fontWeight: 400 }}
            onClick={() => {
              dispatch(setCreateTagVisible(true));
              dispatch(setTagModalOption("Create"));
            }}
          >
            Create your own
          </Button>
          <CreateTagModal />
        </TagsEmpty>
      )}
    </>
  );
};

export default checkAuth(Tags);
