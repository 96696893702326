import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Calendar as AntCalendar,
  Typography,
  Badge,
  Button,
  Row,
  Col,
} from "antd";
import moment from "moment";
import { Moment } from "moment";

import {
  checkAuth,
  UpcomingEvent,
  CreateEventModal,
  EventDetailsModal,
} from "../components";
import { setHeaderTitle } from "../reducers/authSlice";
import {
  setCreateEventVisible,
  setEventModalOption,
  setEventData,
  setEventDetailsVisible,
} from "../reducers/featuresSlice";
import { getAllEvents } from "../api/events";
import { EventResponseType } from "../types/EventTypes";
import { RootState } from "../reducers";

const CalendarDiv = styled.div`
  display: flex;
`;
const CalendarContainer = styled.div`
  width: 70%;
  margin-left: 4.2%;
`;
const EventsDiv = styled.div`
  background-color: #edf5ff;
  width: 20%;
  margin-left: 2.5%;
  min-height: 80vh;
  border-radius: 10px;
  padding: 2.5% 0;
  .ant-row {
    padding: 1% 0;
  }
`;
const StyledCalendar = styled(AntCalendar)`
  .ant-picker-calendar-header {
    .ant-picker-calendar-mode-switch {
      display: none !important;
    }
    .ant-select-selector {
      border-radius: 2px;
    }
  }
  .ant-picker-body {
    z-index: 0;
  }
  .ant-picker-calendar-date-content {
    overflow-x: hidden;
  }
  .ant-picker-cell-inner,
  .ant-picker-cell {
    :hover {
      background-color: #ffffff !important;
    }
  }
  .ant-badge {
    padding-left: 2%;
    :hover {
      background: #e8e9e9;
      border-radius: 2px;
    }
  }
`;

const Calendar = () => {
  const initialEventData = {
    id: "",
    name: "",
    description: "",
    url: undefined,
    startTimestamp: moment(),
    endTimestamp: moment(),
    reminderTimestamp: moment(),
  };
  const [events, setEvents] = useState<EventResponseType["events"]>([
    initialEventData,
  ]);
  const [loading, isLoading] = useState<boolean>(true);
  const createEventVisible = useSelector(
    (state: RootState) => state.features.createEventVisible
  );
  const eventDetailsVisible = useSelector(
    (state: RootState) => state.features.eventDetailsVisible
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeaderTitle("Calendar"));
    getAllEvents()
      .then((data) => {
        data.events.sort(
          (a, b) => Number(a.startTimestamp) - Number(b.startTimestamp)
        );
        setEvents(data.events);
        isLoading(false);
      })
      .catch((error) => console.log(error));
  }, [dispatch, createEventVisible, eventDetailsVisible]);

  const getListData = (value: Moment) => {
    const matchingDateData = events.filter((event) =>
      event.startTimestamp.isSame(value, "day")
    );

    return matchingDateData || [];
  };
  const notificationsColor = ["#2B6FF2", "#1AC491", "#4596A8"];

  const dateCellRender = (value: Moment) => {
    const matchingDateData = getListData(value);
    return (
      <ul>
        {matchingDateData.map((event, index) => (
          <li
            key={event.id}
            onClick={() => {
              console.log(event);
              dispatch(setEventDetailsVisible(true));
              dispatch(setEventData(event));
            }}
          >
            <Badge
              color={notificationsColor[index]}
              text={event.name}
              style={{ whiteSpace: "nowrap" }}
            />
          </li>
        ))}
      </ul>
    );
  };
  return (
    <CalendarDiv>
      <CalendarContainer>
        <Typography.Title style={{ fontSize: "1.5rem" }}>
          {moment().format("[Today, ]D MMM YYYY")}
        </Typography.Title>
        {loading ? "" : <StyledCalendar dateCellRender={dateCellRender} />}
      </CalendarContainer>
      <EventsDiv>
        <Row style={{ paddingBottom: "12.5%" }}>
          <Col span={20} offset={2}>
            <Button
              style={{ width: "100%" }}
              size="large"
              type="primary"
              onClick={() => {
                dispatch(setCreateEventVisible(true));
                dispatch(setEventModalOption("Create"));
                dispatch(setEventData(initialEventData));
              }}
            >
              Create event
            </Button>
            <CreateEventModal />
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <Typography.Title style={{ fontSize: "1.125rem" }}>
              Upcoming events
            </Typography.Title>
          </Col>
        </Row>
        {!loading &&
          events.map((event, index) => {
            return (
              <Row>
                <Col
                  span={20}
                  offset={2}
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    height: "130px",
                    marginBottom: "5%",
                  }}
                >
                  <UpcomingEvent
                    event={event}
                    color={notificationsColor[index % 3]}
                  />
                  <EventDetailsModal />
                </Col>
              </Row>
            );
          })}
      </EventsDiv>
    </CalendarDiv>
  );
};

export default checkAuth(Calendar);
