import styled from "styled-components";
import { Table } from "antd";

const StyledTable = styled(Table)`
.ant-table-cell {
    padding: 0 25px;
  }
  .ant-table-selection-column {
    padding-left: 4.2%;
    padding-right: 100px;
  }
  .ant-table-cell {
    background-color: #ffffff;
    font-size: 1rem;

    ::before {
      display: none;
    }
    border-bottom: 1px solid #d0d0d0;
    height: 4.75rem;
  }
  thead {
    .ant-table-cell {
      color: #000000;
      font-weight: 700;
      height: 3.6rem;
    }
  }
  tr.ant-table-row-selected {
    td {
      background: #eff5ff;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #eff5ff;
    border: 1px solid #2b6ff2;
    box-sizing: border-box;
    border-radius: 2px;
    ::after {
      border: 1px solid #000000;
      border-top: 0;
      border-left: 0;
    }
    ::selection{
      background: #2b6ff2;
    }
  }
  .ant-table-row {
    .ant-table-cell:last-child {
      padding-right: 100px;
    }
  }
}`;

export default StyledTable;
