import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { Row, Col, Divider, Typography, Button, message } from "antd";
import Icon from "@ant-design/icons";
import { DeleteOutlined, InfoCircleFilled } from "@ant-design/icons";

import { checkAuth, SendEmailModal } from "../components";
import {
  setHeaderBackButtonVisible,
  setSendEmailVisible,
} from "../reducers/featuresSlice";
import { setHeaderTitle } from "../reducers/authSlice";
import { StyledTable } from "../styles";
import { CashIcon, PersonIcon, SuitCaseIcon, ExportIcon } from "../icons";
import { ProjectTableType } from "../types/ProjectTypes";
import { getProject } from "../api/projects";

const { Text } = Typography;
const ProjectContainer = styled.div``;
const DetailsContainer = styled.div``;
const DetailsDiv = styled.div`
  height: 160px;
  background: ${(props) => props.color};
  border-radius: 10px;
  margin: 10% 0;
`;
const DetailsTitle = styled(Text)`
  font-size: 0.875rem;
  font-weight: 400;
`;
const DetailsRow = styled(Row)`
  height: 100%;
  flex-direction: column;
`;
const IconCol = styled(Col)`
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 1%;
    path {
      fill: #000000;
    }
  }
  margin-bottom: 5%;
`;
const DataCol = styled(IconCol)`
  font-size: 1rem;
  font-weight: 700;
`;
const OptionsRow = styled(Row)`
  margin-bottom: 2%;
  .ant-btn-icon-only {
    background-color: #eff5ff;
    border-radius: 5px;
    border-color: #ffffff;
  }
`;
const SendEmailButton = styled(Button)`
  width: 100%;
  font-size: 0.875rem;
  border: 1px solid #cbcbcb;
`;
const ExportTimeLogButton = styled(Button)`
  svg {
    height: 22px;
    width: 22px;
  }
`;
const DownloadNotification = styled.div`
  width: 240px;
  height: 40px;
  position: fixed;
  left: calc(50% - 240px / 2 + 118px);
  top: 17px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  z-index: 2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  svg {
    fill: #1890ff;
  }
`;

const ProjectTable = styled(StyledTable)``;

const ProjectDetails = () => {
  useEffect(() => {
    dispatch(setHeaderBackButtonVisible(true));
    dispatch(setHeaderTitle(data.project));
    getProject(data.id)
      .then((response) => console.log(response))
      .catch((error) => {
        message.error(error.response.data.message);
      });
  });
  const [downloadNotifVisible, setDownloadNotifVisible] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    state: { data },
  } = useLocation<{ data: ProjectTableType }>();
  const tableColumns = [
    {
      key: "id",
      title: "Sl no.",
      dataIndex: "id",
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "task_name",
      title: "Task",
      dataIndex: "task",
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "tags",
      title: "Tags",
      dataIndex: "tags",
    },
    {
      key: "time",
      title: "Time",
      dataIndex: "time",
    },
  ];

  const projectData = [
    {
      key: 1,
      id: "1",
      date: "20/07/2021",
      task: "Login",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: "UI Design",
      time: "1:00:00",
    },
  ];

  const handleSelectChange = (rowKeys: any, rows: any) => {
    // console.log(rows, rowKeys);
  };
  return (
    <ProjectContainer>
      <DetailsContainer>
        <Row>
          <Col xs={{ span: 20, offset: 2 }} md={{ span: 6, offset: 1 }}>
            <DetailsDiv color="#FFFDE7">
              <DetailsRow justify="center">
                <IconCol>
                  <SuitCaseIcon height={24} width={24} />
                  <DetailsTitle>Project</DetailsTitle>
                </IconCol>
                <DataCol>{data.project}</DataCol>
              </DetailsRow>
            </DetailsDiv>
          </Col>
          <Col xs={{ span: 20, offset: 2 }} md={{ span: 6, offset: 2 }}>
            <DetailsDiv color="#F5FFF4">
              <DetailsRow justify="center">
                <IconCol>
                  <PersonIcon height={24} width={24} />
                  <DetailsTitle>Client</DetailsTitle>
                </IconCol>
                <DataCol>{data.clientName}</DataCol>
              </DetailsRow>
            </DetailsDiv>
          </Col>
          <Col xs={{ span: 20, offset: 2 }} md={{ span: 6, offset: 2 }}>
            <DetailsDiv color="#FDF5FF">
              <DetailsRow justify="center">
                <IconCol>
                  <CashIcon height={24} width={24} />
                  <DetailsTitle>
                    Rate{data.billingMethod === "Hourly" ? " per hour" : ""}
                  </DetailsTitle>
                </IconCol>
                <DataCol>{data.rate}</DataCol>
              </DetailsRow>
            </DetailsDiv>
          </Col>
        </Row>
        <OptionsRow>
          <Col span={4} xs={{ offset: 2 }} md={{ offset: 1 }}>
            <Button
              size="large"
              icon={<DeleteOutlined style={{ color: "#2B6FF2" }} />}
            />
          </Col>
          <Col
            xs={{ span: 7, offset: 0 }}
            md={{ span: 4, offset: 8 }}
            lg={{ span: 3, offset: 10 }}
          >
            <SendEmailButton
              size="large"
              onClick={() => {
                dispatch(setSendEmailVisible(true));
              }}
            >
              Send Email
            </SendEmailButton>
            <SendEmailModal />
          </Col>
          <Col
            xs={{ span: 10, offset: 1 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 4, offset: 1 }}
          >
            <ExportTimeLogButton
              size="large"
              icon={<Icon component={ExportIcon} />}
              type="primary"
              onClick={() => {
                setDownloadNotifVisible(true);
                setTimeout(() => {
                  setDownloadNotifVisible(false);
                }, 2500);
              }}
            >
              Export Time Log
            </ExportTimeLogButton>
            {downloadNotifVisible ? (
              <DownloadNotification>
                <InfoCircleFilled width={9} height={9} />
                <span>File will be downloaded soon</span>
              </DownloadNotification>
            ) : (
              ""
            )}
          </Col>
        </OptionsRow>
        <Divider
          style={{
            border: "1px solid #D0D0D0",
            margin: "0px",
            width: "100%",
          }}
        />
        <ProjectTable
          rowSelection={{
            type: "checkbox",
            onChange: handleSelectChange,
          }}
          columns={tableColumns}
          dataSource={projectData}
          pagination={false}
          scroll={{ x: "100%" }}
        />
      </DetailsContainer>
    </ProjectContainer>
  );
};

export default checkAuth(ProjectDetails);
