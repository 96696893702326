import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Image, Typography, Button } from "antd";

import { invalidRouteImg } from "../images";

const InvalidRoutePageContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 18px;
    font-weight: 400;
    margin: 2.5% 0% 2.5% 0%;
  }
  .ant-image {
    width: 25%;
  }
  button {
    width: 25%;
    background: #2b6ff2;
    border-radius: 5px;
    font-weight: 700;
  }
  @media (max-width: 900px) {
    .ant-image {
      width: 80%;
    }
    button {
      width: 75%;
    }
  }
  @media (min-width: 900px and max-width:1200px) {
    .ant-image {
      width: 50%;
    }
    button {
      width: 50%;
    }
  } ;
`;
const { Title } = Typography;
const InvalidRoutePage = () => {
  const history = useHistory();
  return (
    <InvalidRoutePageContainer>
      <Image alt="404_img" preview={false} src={invalidRouteImg} />
      <Title>Sorry! The page doesn’t exist.</Title>
      <Button
        type="primary"
        onClick={() => {
          history.push("/");
        }}
      >
        Go home
      </Button>
    </InvalidRoutePageContainer>
  );
};

export default InvalidRoutePage;
