import { EventPostDataType, EventResponseType } from "../types/EventTypes";
import apiAxios from "./axiosConfig";
import moment from "moment";

export const getAllEvents = async () => {
  try {
    const { data }: { data: EventResponseType } = await apiAxios.get("/events");
    data.events.forEach((event) => {
      event.startTimestamp = moment(event.startTimestamp);
      event.endTimestamp = moment(event.endTimestamp);
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const createEvent = async (eventData: EventPostDataType) => {
  try {
    await apiAxios.post("/events", eventData);
  } catch (error) {
    throw error;
  }
};

export const deleteEvent = async (id: string) => {
  try {
    await apiAxios.delete(`/events/${id}`);
  } catch (error) {
    throw error;
  }
};

export const editEvent = async (id: string, eventData: EventPostDataType) => {
  try {
    await apiAxios.put(`/events/${id}`, eventData);
  } catch (error) {
    throw error;
  }
};
