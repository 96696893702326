import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Form, Input, Typography, Button } from "antd";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import {
  setAuthentication,
  setAuthToken,
  setUserId,
} from "../reducers/authSlice";
import { checkNotAuth } from "../components";
import { FormContainer } from "../styles";
import { loginCall } from "../api/auth";

const { Title } = Typography;
const LoginDiv = styled(FormContainer)`
  .ant-row:nth-of-type(5) {
    text-align: center;
  }
  #login-form_title {
    text-align: center;
    font-weight: 700;
  }
  label.ant-form-item-required {
    font-size: 1rem;
    font-weight: 700;
  }
`;
const SignInButton = styled(Button)`
  width: 100%;
  font-weight: 700;
`;
const ForgotPasswordLink = styled(Link)`
  font-weight: 400;
  color: #2b6ff2;
`;
const Errors = styled(Form.Item)`
  background: #ffd8d8;
  color: #252525;
  font-weight: 400;
  padding: 1%;
`;
const ForgotPassword = styled(Form.Item)`
  .ant-form-item-control-input-content {
    text-align: center;
  }
`;
const SignUp = styled(ForgotPassword)`
  margin-bottom: 0;
  a {
    font-weight: 700;
    color: #000000;
  }
`;
const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors] = useState<string>("");
  const handleLogin = ({
    title,
    email,
    password,
  }: {
    title: undefined;
    email: string;
    password: string;
  }) => {
    loginCall({ email, password })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          dispatch(setAuthentication(true));
          dispatch(setAuthToken(response.data.token));
          dispatch(setUserId(response.data.userId));
          history.push("/");
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Invalid login credentials") {
          setErrors("Incorrect email or password ");
        } else {
          setErrors(error.response.data.message);
        }
      });
  };

  return (
    <LoginDiv>
      <Form
        name="login-form"
        layout="vertical"
        onFinish={handleLogin}
        onFinishFailed={() => {}}
        size="large"
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 24,
        }}
        requiredMark={false}
      >
        <Form.Item name="title">
          <Title>Welcome Back</Title>
        </Form.Item>
        {errors.length !== 0 ? (
          <Errors
            wrapperCol={{
              offset: 1,
              span: 24,
            }}
          >
            <Form.ErrorList errors={[errors]} />
          </Errors>
        ) : (
          ""
        )}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter an email!",
            },
            {
              type: "email",
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input placeholder="mail@mail.com" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <SignInButton type="primary" htmlType="submit" size="large">
            Log In
          </SignInButton>
        </Form.Item>
        <SignUp
          wrapperCol={{
            span: 24,
          }}
        >
          <span>
            Don’t have an account ? <Link to="/signup">Sign up</Link>
          </span>
        </SignUp>
        <ForgotPassword
          wrapperCol={{
            span: 24,
          }}
        >
          <ForgotPasswordLink to="/forgot-password">
            Forgot your password
          </ForgotPasswordLink>
        </ForgotPassword>
      </Form>
    </LoginDiv>
  );
};
export default checkNotAuth(Login);
