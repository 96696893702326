import { useLocation } from "react-router";
import InvalidRoutePage from "./InvalidRoutePage";
import VerificationFailed from "../components/VerificationFailed";
import VerificationSuccess from "../components/VerificationSuccess";

const Verification = () => {
  const success = new URLSearchParams(useLocation().search);
  const verify = success.get("success");

  return verify === "true" ? (
    <VerificationSuccess />
  ) : verify === "false" ? (
    <VerificationFailed />
  ) : (
    <InvalidRoutePage />
  );
};

export default Verification;
