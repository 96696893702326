import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Form, Typography, Input, Image, Button } from "antd";

import { FormContainer } from "../styles";
import { checkNotAuth } from "../components";
import { email1, email2 } from "../images";

const { Title, Text } = Typography;
const PasswordResetDiv = styled(FormContainer)`
  form {
    .ant-row:nth-of-type(1),
    .ant-row:nth-of-type(2),
    .ant-row:nth-of-type(5) {
      text-align: center;
    }
    #forgot-password-form_title {
      font-size: 1.5rem;
      font-weight: 700;
    }
    #forgot-password-form_text {
      font-size: 1rem;
      font-weight: 400;
      color: #606060;
    }
    @media (max-width: 1200px) {
      #forgot-password-form_title {
        font-size: 1.5rem;
      }
      #forgot-password-form_text {
        font-size: 1rem;
      }
    }
    @media (max-width: 1280px) and (orientation: landscape) {
      #forgot-password-form_title {
        font-size: 1.5rem;
      }
      #forgot-password-form_text {
        font-size: 1rem;
      }
    }
  }
`;
const SendButton = styled(Button)`
  width: 100%;
`;
const LoginButton = styled(SendButton)``;
const ResendText = styled(Text)`
  font-size: 1rem;
`;
const ResendEmailButton = styled(Button)`
  padding: 0;
  font-size: 1rem;
  font-weight: bold;
`;
const ForgotPassword = () => {
  const [isEmailSent, setResetEmailSent] = useState<boolean>(false);
  const history = useHistory();
  const handleLoginRedirect = () => {
    history.push("/login");
  };
  return (
    <PasswordResetDiv>
      <Form
        name="forgot-password-form"
        layout="vertical"
        requiredMark={false}
        onFinish={() => {
          setResetEmailSent(true);
        }}
        onFinishFailed={() => {}}
        size="large"
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 24,
        }}
      >
        {isEmailSent ? (
          <>
            <Form.Item name="title">
              <Title>Email has been sent!</Title>
            </Form.Item>
            <Form.Item name="text">
              <Text>
                Please check your inbox and click in the received link to reset
                password
              </Text>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
                offset: 9,
              }}
            >
              <Image
                alt="email_icon2"
                width={92.5}
                height={92.5}
                preview={false}
                src={email2}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
            >
              <LoginButton
                type="primary"
                size="large"
                onClick={handleLoginRedirect}
              >
                Login
              </LoginButton>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
            >
              <ResendText>Didn’t receive the link? </ResendText>
              <ResendEmailButton type="link">Resend</ResendEmailButton>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item name="title">
              <Title>Forgot your password?</Title>
            </Form.Item>
            <Form.Item name="text">
              <Text>
                Enter your registered email below to get the password reset
                instructions{" "}
              </Text>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
                offset: 9,
              }}
            >
              <Image
                alt="email_icon1"
                width={92.5}
                height={92.5}
                preview={false}
                src={email1}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter an email!",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="mail@mail.com" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
            >
              <SendButton type="primary" htmlType="submit" size="large">
                Send
              </SendButton>
            </Form.Item>
          </>
        )}
      </Form>
    </PasswordResetDiv>
  );
};

export default checkNotAuth(ForgotPassword);
