import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import {
  Form,
  Row,
  Col,
  Input,
  Radio,
  Typography,
  Select,
  Layout,
  Button,
  message,
} from "antd";

import { checkAuth } from "../components";
import { setHeaderTitle } from "../reducers/authSlice";
import { ClientDataType, ClientFormType } from "../types/ClientTypes";
import { ErrorObjectType } from "../types";
import { editClient } from "../api/clients";

const { Title } = Typography;
const { Option } = Select;
const { Footer } = Layout;
const EditClientDiv = styled.div`
  width: 90%;
  margin-top: -2%;
`;
const EditClientForm = styled(Form)`
  .ant-radio-wrapper {
    padding: 0px 20px;
  }
  .ant-radio-wrapper:first-of-type {
    padding-left: 0px;
  }
  h2.ant-typography {
    font-size: 1.25rem;
    font-weight: 700;
  }
  .ant-form-item-label {
    font-size: 1rem;
    font-weight: 700;
  }
`;
const EditClientFooter = styled(Footer)`
  background: #ffffff;
  width: 100%;
  height: 80px;
  position: sticky;
  bottom: 0px;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.14);
`;
const SaveButton = styled(Button)`
  width: 100%;
`;
const CancelButton = styled(SaveButton)`
  background: #e4e4e4;
  border: 0px;
  :hover,
  :focus {
    background: #e4e4e4;
  }
`;
const ClientAddressInitialValue: ClientDataType["address"] = {
  line1: "",
  line2: "",
  country: "",
  zipCode: 0,
  state: "",
  city: "",
};
const EditClient = () => {
  useEffect(() => {
    dispatch(setHeaderTitle("Edit Client"));
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    state: { client },
  } = useLocation<{ client: ClientDataType }>();
  const [companyNameVisible, setCompanyNameVisible] = useState<boolean>(
    client.type === "BUSINESS"
  );
  const [address] = useState(
    client.address === undefined || client.address === null
      ? ClientAddressInitialValue
      : client.address
  );
  const [form] = Form.useForm();
  const saveClientEdits = () => {
    form
      .validateFields()
      .then((values: ClientFormType) => {
        // form.resetFields();
        let {
          country,
          line1,
          line2,
          zipCode,
          city,
          state,
          attention,
          ...formData
        } = values;
        zipCode = Number(zipCode);
        const clientAddress = { country, line1, line2, zipCode, city, state };
        const clientData = { address: clientAddress, ...formData };
        if (clientData.type === "INDIVIDUAL") {
          clientData.companyName = "";
        }
        editClient(client.id, clientData)
          .then(() => {
            history.push("/clients");
          })
          .catch((error) => {
            if (error.response.data.message) {
              message.error(error.response.data.message);
            }
            const requestErrors = error.response.data.errors;
            const validationErrors: ErrorObjectType = {};
            if (requestErrors !== undefined || requestErrors !== null) {
              requestErrors.forEach((error: { param: string; msg: string }) => {
                if (error.param.startsWith("address")) {
                  error.param = error.param.substring(
                    error.param.indexOf(".") + 1
                  );
                }
                if (!validationErrors[error.param]) {
                  validationErrors[error.param] = [];
                }
                validationErrors[error.param].push(error.msg);
                form.setFields([
                  {
                    name: error.param,
                    errors: validationErrors[error.param],
                  },
                ]);
              });
            }
          });
      })
      .catch(() => {});
  };
  return (
    <>
      <EditClientDiv>
        <EditClientForm
          form={form}
          layout="vertical"
          requiredMark={false}
          size="middle"
          initialValues={client}
        >
          <Row>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item
                name="contactFirstName"
                label="First name"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                ]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item
                name="contactLastName"
                label="Last name"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                ]}
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={20} offset={1}>
              <Form.Item
                name="type"
                label="Client type"
                rules={[
                  {
                    required: true,
                    message: "Please select client type",
                  },
                ]}
              >
                <Radio.Group
                  size="large"
                  onChange={(e: any) => {
                    setCompanyNameVisible(e.target.value === "BUSINESS");
                  }}
                >
                  <Radio value="BUSINESS">Business</Radio>
                  <Radio value="INDIVIDUAL">Individual</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {companyNameVisible ? (
            <Row>
              <Col xs={10} lg={6} offset={1}>
                <Form.Item name="companyName" label="Company name">
                  <Input placeholder="Enter company name" />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item name="phone" label="Contact phone">
                <Input placeholder="Enter contact phone" />
              </Form.Item>
            </Col>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item
                name="email"
                label="Contact email"
                rules={[
                  {
                    required: true,
                    message: "Please enter contact email",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input placeholder="Enter contact email" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} offset={1}>
              <Title level={2}>Address</Title>
            </Col>
          </Row>
          <Row>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item name="attention" label="Attention">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item
                name="country"
                label="Country/Region"
                initialValue={address.country}
              >
                <Select placeholder="Select">
                  <Option value="India">India</Option>
                  <Option value="U.S.A">U.S.A</Option>
                  <Option value="Australia">Australia</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item
                initialValue={address.line1}
                name="line1"
                label="Address"
              >
                <Input.TextArea
                  rows={3}
                  style={{ resize: "none" }}
                  placeholder="Street 1"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item
                initialValue={address.line2}
                name="line2"
                label="Address"
              >
                <Input.TextArea
                  rows={3}
                  style={{ resize: "none" }}
                  placeholder="Street 2"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item name="city" initialValue={address.city} label="City">
                <Input placeholder="Enter city" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item
                name="state"
                initialValue={address.state}
                label="State"
              >
                <Select placeholder="Select state">
                  <Option value="Kerala">Kerala</Option>
                  <Option value="Delhi">Delhi</Option>
                  <Option value="Tamil Nadu">Tamil Nadu</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={10} lg={6} offset={1}>
              <Form.Item
                name="zipCode"
                label="Zipcode"
                initialValue={address.zipCode === 0 ? "" : address.zipCode}
                rules={[
                  {
                    pattern: /^(0|[1-9][0-9]*)$/,
                    message: "Please enter a valid Zipcode",
                  },
                ]}
              >
                <Input placeholder="Enter zipcode" />
              </Form.Item>
            </Col>
          </Row>
        </EditClientForm>
      </EditClientDiv>
      <EditClientFooter>
        <Row style={{ width: "100%" }}>
          <Col xs={6} lg={2} offset={0}>
            <SaveButton type="primary" onClick={saveClientEdits}>
              Save
            </SaveButton>
          </Col>
          <Col xs={6} lg={2} offset={1}>
            <CancelButton onClick={() => history.push("/clients")}>
              Cancel
            </CancelButton>
          </Col>
        </Row>
      </EditClientFooter>
    </>
  );
};

export default checkAuth(EditClient);
