import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  HomeDiv,
  SubHead,
  SubHead1,
  DateText,
  DateCardyellow,
  DateCardgreen,
  DateCardpink,
  WorkHours,
  Content,
  ContentSub,
  Center,
  GraphBox,
  Hours,
  Days,
  GraphHead,
} from "../styles/StyledDashboard";
import { Image } from "antd";
import graph from "../images/graph.svg";
import { checkAuth } from "../components";
import { setHeaderTitle } from "../reducers/authSlice";

const Dashboard = () => {
  useEffect(() => {
    dispatch(setHeaderTitle("Dashboard"));
  });
  const dispatch = useDispatch();
  return (
    <HomeDiv>
      <SubHead1>
        <DateText>23 July, 2021</DateText>
        <DateCardyellow>
          <div>
            <Center>
              <Content>Start Time</Content>
            </Center>
            <Center>
              <ContentSub> 10:00 am </ContentSub>
            </Center>
          </div>
        </DateCardyellow>
        <DateCardgreen>
          <div>
            <Center>
              <Content>Total working time</Content>
            </Center>
            <Center>
              <ContentSub>7 hours</ContentSub>
            </Center>
          </div>
        </DateCardgreen>
        <DateCardpink>
          <div>
            <Center>
              <Content>Active Projects</Content>
            </Center>
            <Center>
              <ContentSub>5</ContentSub>
            </Center>
          </div>
        </DateCardpink>
      </SubHead1>
      <SubHead>
        <GraphHead>
          <WorkHours>Work Hours from 1-7 July 2021</WorkHours>
        </GraphHead>
        <GraphBox>
          <Hours>Hours</Hours>
          <Center>
            <Image alt="graph analysis" preview={false} src={graph} />
            <br />
            <Days>Days</Days>
          </Center>
        </GraphBox>
      </SubHead>
    </HomeDiv>
  );
};

export default checkAuth(Dashboard);
