import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Row, Col, Input, Radio, Button, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { StyledModal } from "../styles";
import { setCreateTagVisible } from "../reducers/featuresSlice";
import { RootState } from "../reducers";
import { createTag, deleteTag, editTag } from "../api/tags";
import { errorHandling } from "../config/addModalErrorHandling";

const StyledCreateTagModal = styled(StyledModal)`
  @media (min-width: 1200px) {
    left: 7.5%;
  }
`;
const TagColors = styled(Radio.Group)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #e9f0ff;
  height: 50px;
  border-radius: 5px;
  .ant-radio-button-wrapper {
    width: 20px;
    border-radius: 3px !important;
    height: 20px;
    padding: 0;
    border: 0;
    ::before {
      display: none;
    }
  }
  .ant-radio-button-wrapper-checked {
    box-shadow: 0 0 0 3px #93b3ef !important;
  }
`;
const DeleteTagButton = styled(Button)`
  border-style: none;
  color: #ff2121;
  :hover,
  :active,
  :focus {
    color: #ff2121;
  }
`;

const CreateTagModal = () => {
  const [form] = Form.useForm();
  const tagData = useSelector((state: RootState) => state.features.tagData);
  const createTagVisible = useSelector(
    (state: RootState) => state.features.createTagVisible
  );
  const tagModalOption = useSelector(
    (state: RootState) => state.features.tagModalOption
  );
  useEffect(() => {
    if (tagModalOption === "Create") {
      form.resetFields();
    } else {
      form.setFieldsValue(tagData);
    }
  }, [form, tagData, tagModalOption]);

  const tagColors = ["#4C6BA6", "#E87373", "#1B2F62", "#249299", "#00A1D4"];
  const dispatch = useDispatch();
  return (
    <StyledCreateTagModal
      centered
      visible={createTagVisible}
      width="382px"
      title={`${tagModalOption} tag`}
      okText="Save"
      cancelText="Cancel"
      onCancel={() => {
        dispatch(setCreateTagVisible(false));
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (tagModalOption === "Create") {
              createTag(values)
                .then((response) => console.log(response))
                .catch((error) => errorHandling(error, form));
            } else if (tagModalOption === "Edit") {
              editTag(values, tagData.id)
                .then((response) => console.log(response))
                .catch((error) => errorHandling(error, form));
            }
            dispatch(setCreateTagVisible(false));
            form.resetFields();
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="filter_log"
        requiredMark={false}
      >
        <Row>
          <Col span={20} offset={2}>
            <Form.Item
              name="label"
              label="Label"
              rules={[
                {
                  required: true,
                  message: "Enter a label",
                },
              ]}
            >
              <Input placeholder="Eg: UI Design" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <Form.Item
              name="color"
              label="Color"
              rules={[
                {
                  required: true,
                  message: "Select a color",
                },
              ]}
            >
              <TagColors onChange={() => {}}>
                {tagColors.map((tagColor: string) => {
                  return (
                    <Radio.Button
                      key={tagColor}
                      value={tagColor}
                      style={{
                        background: tagColor,
                      }}
                    ></Radio.Button>
                  );
                })}
              </TagColors>
            </Form.Item>
          </Col>
        </Row>
        {tagModalOption === "Edit" ? (
          <Row>
            <Col span={10} offset={2}>
              <DeleteTagButton
                icon={<DeleteOutlined />}
                onClick={() => {
                  deleteTag(tagData.id)
                    .then(() => dispatch(setCreateTagVisible(false)))
                    .catch((error) => {
                      if (error.response.data.message) {
                        message.error(error.response.data.message);
                      }
                    });
                }}
              >
                Delete tag
              </DeleteTagButton>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Form>
    </StyledCreateTagModal>
  );
};

export default CreateTagModal;
