import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Input, Radio } from "antd";

import { StyledModal } from "../styles";
import { RootState } from "../reducers";
import { setAddClientVisible } from "../reducers/featuresSlice";
import { createClient } from "../api/clients";
import { errorHandling } from "../config/addModalErrorHandling";

const StyledAddClientModal = styled(StyledModal)`
  .ant-modal-title {
    font-family: Roboto;
  }
  .ant-radio-wrapper {
    padding: 0px 20px;
  }
  .ant-radio-wrapper:first-of-type {
    padding-left: 0px;
  }
`;

const AddClientModal = () => {
  const dispatch = useDispatch();
  const addClientVisible = useSelector(
    (state: RootState) => state.features.addClientVisible
  );
  const [form] = Form.useForm();
  return (
    <StyledAddClientModal
      centered
      visible={addClientVisible}
      width="587px"
      title="Add client"
      okText="Add"
      cancelText="Cancel"
      onCancel={(close) => {
        dispatch(setAddClientVisible(false));
      }}
      onOk={() => {
        form
          .validateFields()
          .then((clientData) => {
            createClient(clientData)
              .then(() => {
                dispatch(setAddClientVisible(false));
                form.resetFields();
              })
              .catch((error) => {
                errorHandling(error, form);
              });
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_client"
        requiredMark={false}
      >
        <Row gutter={[0, 15]}>
          <Col span={9} offset={2}>
            <Form.Item
              name="contactFirstName"
              label="First name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
              ]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col span={9} offset={2}>
            <Form.Item
              name="contactLastName"
              label="Last name"
              rules={[
                {
                  required: true,
                  message: "Please enter last name",
                },
              ]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item
              name="type"
              label="Client type"
              rules={[
                {
                  required: true,
                  message: "Please select client type",
                },
              ]}
            >
              <Radio.Group size="large">
                <Radio value="BUSINESS">Business</Radio>
                <Radio value="INDIVIDUAL">Individual</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item
              name="phone"
              label="Contact phone"
              rules={[
                {
                  max: 10,
                  message: "Enter valid phone number",
                },
                {
                  min: 10,
                  message: "Enter valid phone number",
                },
              ]}
            >
              <Input placeholder="Enter contact phone" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 15]}>
          <Col span={20} offset={2}>
            <Form.Item
              name="email"
              label="Contact email"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="johndoe@gmail.com" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledAddClientModal>
  );
};

export default AddClientModal;
