import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialAuthStateProps {
  headerTitle: string;
  isAuthenticated: boolean;
  authToken: string;
  userId: string;
}

const initialAuthState: initialAuthStateProps = {
  headerTitle: "",
  isAuthenticated: false,
  authToken: "",
  userId: "",
};
const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setHeaderTitle: (state, action: PayloadAction<string>) => {
      state.headerTitle = action.payload;
    },
    setAuthentication: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
  },
});
export const { setHeaderTitle, setAuthentication, setAuthToken, setUserId } =
  authSlice.actions;
export default authSlice.reducer;
