import apiAxios from "./axiosConfig";
import {
  ProjectDataType,
  ProjectFormType,
  ProjectResponseType,
} from "../types/ProjectTypes";

export const createProject = async (projectData: ProjectFormType) => {
  try {
    await apiAxios.post("/projects", projectData);
  } catch (error) {
    throw error;
  }
};

export const getAllProjects = async (
  pageSize?: number,
  pageNumber?: number
) => {
  try {
    const { data }: { data: ProjectResponseType } = await apiAxios.get(
      "/projects",
      { params: { pageSize, pageNumber } }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getProject = async (id: string) => {
  try {
    const { data }: { data: ProjectDataType } = await apiAxios.get(
      `/projects/${id}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};
