import { TagDataType, TagResponseType } from "../types/TagTypes";
import apiAxios from "./axiosConfig";

export const getAllTags = async () => {
  try {
    const { data }: { data: TagResponseType } = await apiAxios.get("/tags");
    return data;
  } catch (error) {
    throw error;
  }
};

export const createTag = async (tagData: TagDataType) => {
  try {
    await apiAxios.post("/tags", tagData);
  } catch (error) {
    throw error;
  }
};

export const editTag = async (tagData: TagDataType, id: string) => {
  try {
    await apiAxios.put(`/tags/${id}`, tagData);
  } catch (error) {
    throw error;
  }
};

export const deleteTag = async (id: string) => {
  try {
    await apiAxios.delete(`/tags/${id}`);
  } catch (error) {
    throw error;
  }
};
