import { FormInstance, message } from "antd";

import { ErrorObjectType } from "../types";

export const errorHandling = (error: any, form: FormInstance<any>) => {
  if (error.response.data.message) {
    message.error(error.response.data.message);
  }
  const requestErrors = error.response.data.errors;
  const validationErrors: ErrorObjectType = {};
  if (requestErrors !== undefined || requestErrors !== null) {
    requestErrors.forEach((error: { param: string; msg: string }) => {
      if (!validationErrors[error.param]) {
        validationErrors[error.param] = [];
      }
      validationErrors[error.param].push(error.msg);
      form.setFields([
        {
          name: error.param,
          errors: validationErrors[error.param],
        },
      ]);
    });
  }
};
