import React from "react";
import styled from "styled-components";
import { Typography, Image } from "antd";
import { verificationImage } from "../images";

const { Title, Text } = Typography;

const ContainerDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const VerifyEmailDiv = styled.div`
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.14);
  text-align: center;
  padding: 3rem;
  border-radius: 5px;
  h1.ant-typography {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;

    color: #ff0202;
    text-align: center;
  }
  span.ant-typography {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #777;
    align-items: center;
  }
  img.ant-image-img {
    width: 200px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 214px;
`;

const VerificationFailed = () => {
  return (
    <ContainerDiv>
      <VerifyEmailDiv>
        <Title>Verification failed</Title>
        <Text>
          The link we sent to your mail has been
          <br /> expired and the verification is not complete.
          <br />
          Please try again
        </Text>
        <ImageContainer>
          <Image
            alt="email_icon1"
            width={92.5}
            height={92.5}
            preview={false}
            src={verificationImage}
          />
        </ImageContainer>
      </VerifyEmailDiv>
    </ContainerDiv>
  );
};

export default VerificationFailed;
