import apiAxios from "./axiosConfig";
import { ClientResponseType, ClientDataType } from "../types/ClientTypes";

export const createClient = async (clientData: ClientDataType) => {
  try {
    await apiAxios.post("/clients", clientData);
  } catch (error) {
    throw error;
  }
};
export const getAllClients = async (pageSize?: number, pageNumber?: number) => {
  try {
    const { data }: { data: ClientResponseType } = await apiAxios.get(
      "/clients",
      { params: { pageSize, pageNumber } }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const editClient = async (id: string, clientData: ClientDataType) => {
  try {
    await apiAxios.put(`/clients/${id}`, clientData);
  } catch (error) {
    throw error;
  }
};
